import { User, UserDocRef } from "../models/models";
import { FirestoreDataConverter, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { deleteAccount } from "../firebase/firebaseAuth";

const userConverter: FirestoreDataConverter<UserDocRef> = {
  toFirestore: (data) => {
    return data;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return { ...data, id: snapshot.id } as UserDocRef;
  },
};

export async function createUser(userId: string, user: User) {
  try {
    const userDoc = doc(db, "users", userId).withConverter(userConverter);
    // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    // updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    await setDoc(userDoc, user);
  } catch (e) {
    console.error("Error creating user: ", e);
    throw e;
  }
}

// usersのSubCollection（symptomsとactions）をClient Sideから削除できないため、
// usersのdocにdeletedフラグを追加して、authから削除する。後で手動で消す。
export async function deleteUser(userId: string, user: User) {
  const userDoc = doc(db, "usersToDelete", userId);
  await setDoc(userDoc, { ...user, deleted: true });
  await deleteAccount();
}
