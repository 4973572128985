import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import TodayIcon from "@mui/icons-material/Today";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate, useLocation } from "react-router-dom";

export default function MainMenu() {
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(pathname);
  const navigate = useNavigate();

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        navigate(`${newValue}`);
        setValue(newValue);
      }}
    >
      <BottomNavigationAction label="カレンダー" value="/" icon={<TodayIcon />} />
      <BottomNavigationAction label="体調を記録" value="/symptom-form" icon={<AddIcon />} />
      <BottomNavigationAction label="その他" value="/others" icon={<MoreHorizIcon />} />
    </BottomNavigation>
  );
}
