import FullCalendar, { DatesSetArg, EventClickArg, EventInput } from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import jaLocale from "@fullcalendar/core/locales/ja";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import React from "react";
import { ActionDocRef, Symptom, SymptomDocRef } from "../models/models";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Alert, Box } from "@mui/material";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { isSameDate, prevMonthFirst, today } from "../utils/dateUtil";
import ConditionRating from "./conditionRating";
import { averageStarOfMax } from "../utils/symptomUtil";
import { isAfter, isSameMonth } from "date-fns";
import "./calendar.css";
import { useEventData } from "../context/EventContext";
import { Colors } from "../colors";
import Snackbar from "@mui/material/Snackbar";
import { Config } from "../config";

export type DateItem = {
  date: Date;
  symptom: SymptomDocRef | null;
  action: ActionDocRef | null;
  eventType: "symptom" | "action" | null;
};

type Prop = {
  onClickDate: (item: DateItem) => void;
};

const CalendarComponent = ({ onClickDate }: Prop) => {
  const googleCalendarApiKey = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY ?? "";

  const { symptoms, actions, loadEventData } = useEventData();
  const [displayedMonth, setDisplayedMonth] = React.useState<Date | null>(null);

  const selectedSymptom = (date: Date): SymptomDocRef | null => {
    const symptom = symptoms.find((x) => isSameDate(x.date, date));
    return symptom === undefined ? null : symptom;
  };
  const selectedAction = (date: Date): ActionDocRef | null => {
    const action = actions.find((x) => isSameDate(x.date, date));
    return action === undefined ? null : action;
  };

  const [open, setOpen] = React.useState(false);

  const handleDatesSet = (info: DatesSetArg) => {
    const thisMonth = prevMonthFirst(info.end);
    // console.log("DateSet:", info);

    if (displayedMonth === null || !isSameMonth(displayedMonth, thisMonth)) {
      setDisplayedMonth(thisMonth);

      if (isAfter(thisMonth, prevMonthFirst(today(), Config.ReadableEventBeforeMonth))) {
        setOpen(false);
        loadEventData(info.start, info.end);
      } else {
        setOpen(true);
      }
    }
  };

  // https://fullcalendar.io/docs/dateClick
  const handleClickDate = (info: DateClickArg) => {
    // console.log("clickDate", info);
    onClickDate({
      date: info.date,
      eventType: null,
      symptom: selectedSymptom(info.date),
      action: selectedAction(info.date),
    });
  };

  // https://fullcalendar.io/docs/eventClick
  const handleClickEvent = (info: EventClickArg) => {
    // console.log("clickEvent", info);
    if (info.event.display === "background") return;

    const date = info.event.start;
    if (date === null) return;

    onClickDate({
      date,
      eventType: info.event.extendedProps.type,
      symptom: selectedSymptom(date),
      action: selectedAction(date),
    });
  };

  // https://fullcalendar.io/docs/event-render-hooks
  const renderEventContent = (info: any) => {
    // console.log(info);
    if (info.event.display === "background") return;

    switch (info.event.extendedProps.type) {
      case "symptom":
        const symptom = info.event.extendedProps.data as Symptom;
        const maxRating = 3;
        const num = averageStarOfMax(symptom, maxRating);
        return (
          <>
            <ConditionRating
              name="throatRating"
              value={num}
              max={maxRating}
              precision={0.1}
              getLabelText={(value: number) => `${value} Heart${value !== 1 ? "s" : ""}`}
              sx={{ fontSize: 14 }}
              readOnly
              icon={<FavoriteIcon fontSize="inherit" />}
              emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
            />
          </>
        );
      case "action":
        return <DirectionsWalkIcon sx={{ fontSize: 14 }} />;
    }
  };

  const events = (): EventInput[] => {
    return symptoms
      .map((x) => {
        return {
          title: "0", // for event order
          date: x.date,
          extendedProps: {
            type: "symptom",
            data: x,
          },
        } as EventInput;
      })
      .concat(
        actions.map((x) => {
          return {
            title: "1",
            date: x.date,
            extendedProps: {
              type: "action",
              data: x,
            },
            // display: "block",
          } as EventInput;
        })
      )
      .concat([
        // 日曜日の背景色
        {
          daysOfWeek: [0],
          display: "background",
          color: Colors.HolidayBackGroundColor,
          // overLap: false,
          // allDay: true
        },
        // 土曜日の背景色
        {
          daysOfWeek: [6],
          display: "background",
          color: Colors.SaturdayBackGroundColor,
          // overLap: false,
          // allDay: true
        },
      ]);
  };

  return (
    <>
      <Box sx={{ my: 1, mx: 1 }}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, googleCalendarPlugin]}
          googleCalendarApiKey={googleCalendarApiKey}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev",
            // left: "prevYear,prev",
            center: "title",
            right: "today next",
            // right: "today next,nextYear",
          }}
          height={495} // すべての週の1日に体調とアクションがあったときにスクロールが表示されない高さの最低
          // height={470}
          // contentHeight={300}
          // contentHeight="auto"
          // aspectRatio={1}
          // height={"auto"}
          locale={jaLocale}
          // selectable={true}
          timeZone="UTC" // 日付の生成はすべてUTCで行うため、表示もUTCにする。
          // timeZone="asia/tokyo"
          // titleFormat={{
          //     month: 'long',
          //     year: 'numeric',
          //     day: 'numeric',
          //     weekday: 'long'
          // }}
          dayCellContent={(e: any) => {
            e.dayNumberText = e.dayNumberText.replace("日", "");
          }}
          // initialDate={displayedMonth} // will be parsed as local
          // validRange={{
          //   start: "2017-05-01",
          //   end: "2021-12-29",
          // }}
          // dayHeaderDidMount={(e: any) => {
          // console.log("dayHeaderDidMount", e);
          // }}
          // eventDisplay={(e: any): any => {
          //   console.log("eventDisplay", e);
          // }}
          // datesRender={(e: any) => {
          //   console.log("datesRender", e);
          // }}
          // viewSkeletonRender={(e: any) => {
          //   console.log("viewSkeletonRender", e);
          // }}
          // eventBorderColor="#aa0000"
          // eventBackgroundColor="#aa0000"
          eventSources={[
            {
              events: events(),
              // events: [
              //     { title: 'symptom 1', date: '2021-12-12T00:00' },
              // ],
              // color: 'black',
              // textColor: 'black',
              // backgroundColor: 'white'
            },
            {
              googleCalendarId: "japanese__ja@holiday.calendar.google.com",
              display: "background",
              color: Colors.HolidayBackGroundColor,
              className: "google-holiday",
            },
          ]}
          datesSet={handleDatesSet}
          eventContent={renderEventContent}
          dateClick={handleClickDate}
          eventClick={handleClickEvent}
        />
      </Box>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={open} sx={{ top: 255 }}>
        <Alert severity="warning">
          {Config.ReadableEventBeforeMonth - 1}ヶ月より前のデータは表示できません。
        </Alert>
      </Snackbar>
    </>
  );
};

export default React.memo(CalendarComponent);
