import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "../context/AuthContext";
import LoadingUser from "./loadingUser";

export default function PublicRoute({ children }: any) {
  const { user, loadingUser } = useUser();
  if (loadingUser) {
    return <LoadingUser />;
  }
  return user === null ? <Outlet /> : <Navigate to="/" />;
}
