import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { deleteAccount, logout } from "../firebase/firebaseAuth";
import ConfirmDialog from "../components/confirmDialog";
import { useNavigate } from "react-router-dom";
import React from "react";
import { logDeleteAccount, logLogout, logScreenView } from "../services/EventLogger";

export default function Others() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const openDeleteDialog = () => setOpenDelete(true);
  const closeDeleteDialog = () => setOpenDelete(false);

  const handleLogoutOk = async () => {
    try {
      await logout();
      logLogout();
    } catch (e) {
      console.log("Failed to logout", e);
    }
  };

  const handleDeleteAccountOk = async () => {
    try {
      await deleteAccount();
      logDeleteAccount();
    } catch (e) {
      console.log("Failed to delete account", e);
    }
  };

  React.useEffect(() => {
    logScreenView("others", "Others");
  }, []);

  return (
    <>
      <List
        sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}
        component="nav"
        aria-labelledby="nested-list-subheader1"
        subheader={
          <ListSubheader component="div" disableSticky={true} sx={{ bgcolor: "#dfdfdf" }}>
            ガイド
          </ListSubheader>
        }
      >
        <ListItem disablePadding divider>
          <ListItemButton sx={{ p: 2 }} onClick={() => navigate("/how-to-use")}>
            <ListItemText primary="風邪ログの使い方" />
          </ListItemButton>
        </ListItem>
      </List>
      <List
        sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}
        component="nav"
        aria-labelledby="nested-list-subheader2"
        subheader={
          <ListSubheader component="div" disableSticky={true} sx={{ bgcolor: "#dfdfdf" }}>
            規約
          </ListSubheader>
        }
      >
        <ListItem disablePadding divider>
          <ListItemButton sx={{ p: 2 }} onClick={() => navigate("/terms")}>
            <ListItemText primary="利用規約" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding divider>
          <ListItemButton sx={{ p: 2 }} onClick={() => navigate("/privacy")}>
            <ListItemText primary="プライバシーポリシー" />
          </ListItemButton>
        </ListItem>
      </List>
      <List
        sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}
        component="nav"
        aria-labelledby="nested-list-subheader3"
        subheader={
          <ListSubheader component="div" disableSticky={true} sx={{ bgcolor: "#dfdfdf" }}>
            ご報告
          </ListSubheader>
        }
      >
        <ListItem disablePadding divider>
          <ListItemButton
            sx={{ p: 2 }}
            onClick={() => {
              logScreenView("incident-form-link", "Others");
              window.open(`${process.env.REACT_APP_INCIDENT_FORM_URL}`, "_blank");
            }}
          >
            <ListItemText primary="不具合のご報告" />
          </ListItemButton>
        </ListItem>
      </List>
      <List
        sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}
        component="nav"
        aria-labelledby="nested-list-subheader4"
        subheader={
          <ListSubheader component="div" disableSticky={true} sx={{ bgcolor: "#dfdfdf" }}>
            その他
          </ListSubheader>
        }
      >
        <ListItem disablePadding divider>
          <ListItemButton sx={{ p: 2 }} onClick={openDialog}>
            <ListItemText primary="ログアウト" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding divider>
          <ListItemButton sx={{ p: 2 }} onClick={openDeleteDialog}>
            <ListItemText primary="アカウント削除" />
          </ListItemButton>
        </ListItem>
      </List>
      <ConfirmDialog
        title="ログアウト"
        message="ログアウトしますか？"
        ok="ログアウト"
        open={open}
        handleOk={handleLogoutOk}
        handleCancel={closeDialog}
      />
      <ConfirmDialog
        title="アカウント削除"
        message="アカウントを削除しますか？削除したアカウントの体調ログや行動ログはすべて削除され、復元できません。"
        ok="削除"
        open={openDelete}
        handleOk={handleDeleteAccountOk}
        handleCancel={closeDeleteDialog}
      />
    </>
  );
}
