import { analytics } from "../firebase/firebase";
import { logEvent } from "firebase/analytics";
import { SymptomDocRef } from "../models/models";

type ViewType =
  | "index"
  | "calendar"
  | "action-form"
  | "symptom-form"
  | "others"
  | "terms"
  | "privacy"
  | "how-to-use"
  | "incident-form-link";

export const logScreenView = (screenName: ViewType, screenClass: string) => {
  logEvent(analytics, "screen_view", { firebase_screen: screenName, firebase_screen_class: screenClass });
};

export const logCreateUser = () => {
  logEvent(analytics, "create_user");
};

export const logAddSymptom = (symptom: SymptomDocRef) => {
  logEvent(analytics, "add_symptom", {
    temperature: symptom.temperature === null ? null : symptom.temperature.toString(),
  });
};

export const logUpdateSymptom = () => {
  logEvent(analytics, "update_symptom");
};

export const logDeleteSymptom = () => {
  logEvent(analytics, "delete_symptom");
};

export const logAddAction = () => {
  logEvent(analytics, "add_action");
};

export const logUpdateAction = () => {
  logEvent(analytics, "update_action");
};

export const logDeleteAction = () => {
  logEvent(analytics, "delete_action");
};

export const logLogin = () => {
  logEvent(analytics, "login");
};

export const logLogout = () => {
  logEvent(analytics, "logout");
};

export const logDeleteAccount = () => {
  logEvent(analytics, "delete_account");
};

export const logError = (errorType: string) => {
  logEvent(analytics, "error", { error_type: errorType });
};

// NOTE: Comment out because it is not able to get click event of buttons in FullCalendar.
// export const logNextMonthCalendar = () => {
//   logEvent(analytics, "next_month_calendar");
// };

// export const logPreviousMonthCalendar = () => {
//   logEvent(analytics, "prev_month_calendar");
// };

// export const logTodayCalendar = () => {
//   logEvent(analytics, "today_calendar");
// };
