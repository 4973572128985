import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Prop = {
  title?: string | null;
  message?: string | null;
  ok?: string | null;
  cancel?: string | null;
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

export default function ConfirmDialog({
  open,
  handleOk,
  handleCancel,
  title,
  message,
  ok = "OK",
  cancel = "キャンセル",
}: Prop) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{cancel}</Button>
          <Button variant="outlined" color="error" onClick={handleOk}>
            {ok}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
