import { Card, CardContent, Typography, Container, IconButton, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import React from "react";
import { logScreenView } from "../services/EventLogger";

export default function HowToUse() {
  const navigate = useNavigate();

  React.useEffect(() => {
    logScreenView("how-to-use", "HowToUse");
  }, []);

  return (
    <>
      <Container fixed sx={{ m: 0, p: 0 }}>
        {/* <Container fixed sx={{ bgcolor: "#689f38", m: 0, p: 0 }}> */}
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Typography component="p" variant="h6" sx={{ mt: 1.5 }}>
              風邪ログの使い方
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" size="large" onClick={() => navigate(-1)}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
        <Card sx={{ m: 2 }}>
          <CardContent>
            <Typography component="p" variant="body1">
              「体調が悪いな」と感じたら、体調を風邪ログに記録しましょう。
              <br />
              体調が完全に治るまで、毎日記録します。
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ m: 2 }}>
          <CardContent>
            <Typography component="p" variant="body1">
              体調が悪くなった日の前日や数日前の行動を、風邪ログに記録しましょう。
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ m: 2 }}>
          <CardContent>
            <Typography component="p" variant="body1">
              記録した体調と行動のログを見返すと、
              <br />
              ・自分がどんなときに風邪を引くのかわかるようになる。風邪の予防に役立つ。
              <br />
              ・自分の風邪が治るまでどのくらいの期間かかるのかわかるようになる。風邪を引いてしまったときのスケジュールの調整に役立つ。
              <br />
              ・自分が風邪が何をすれば早く治るのかわかるようになる。風邪の回復に役立つ。
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ m: 2 }}>
          <CardContent>
            <Typography component="p" variant="subtitle1">
              注意事項
            </Typography>
            <Typography component="p" variant="body1">
              ※風邪ログは体調や行動を記録するアプリであり、直接体調に影響を及ぼしたり、医学的なアドバイスをするアプリではありません。
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
