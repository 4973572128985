import { Container, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#689f38",
    },
    secondary: {
      main: "#486f27",
    },
  },
  // https://mui.com/customization/typography/
  // typography: {
  // fontFamily: [
  //   "-apple-system",
  //   "BlinkMacSystemFont",
  //   '"Segoe UI"',
  //   "Roboto",
  //   '"Helvetica Neue"',
  //   "Arial",
  //   "sans-serif",
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(","),
  // In Chinese and Japanese the characters are usually larger,
  // so a smaller fontsize may be appropriate.
  // fontSize: 12,
  // },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" sx={{ p: 0 }}>
        <Outlet />
      </Container>
    </ThemeProvider>
  );
}

export default App;
