import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/AuthContext";
import { useEventData } from "../context/EventContext";
import { ActionDocRef } from "../models/models";
import { deleteAction } from "../services/actionService";
import { addDays, today, toMDString, toYMDString } from "../utils/dateUtil";
import ConfirmDialog from "./confirmDialog";
import { isBefore, isAfter, subDays } from "date-fns";
import { logDeleteAction } from "../services/EventLogger";

export type Props = {
  date: Date;
  action: ActionDocRef | null;
};

export default function ActionDetails({ date, action }: Props) {
  const navigate = useNavigate();
  const { deleteActionEvent } = useEventData();
  const { user } = useUser();

  const [open, setOpen] = React.useState(false);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const handleAdd = () => {
    navigate(`/action-form?date=${toYMDString(date)}`);
  };

  const canAddAction = (): boolean => {
    const today_ = today();
    const tomorrow = addDays(today_, 1);
    return isBefore(date, tomorrow) && isAfter(date, subDays(today_, 32));
  };

  if (action === null) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography component="legend" variant="body1">
              行動が記録されていません
            </Typography>
            <br />
            {!canAddAction() && (
              <Typography component="legend" variant="body1">
                明日以降、あるいは1ヶ月より前の行動は記録できません。
              </Typography>
            )}
          </Box>
          {canAddAction() && (
            <Button variant="outlined" size="medium" onClick={handleAdd}>
              記録
            </Button>
          )}
        </Box>
      </>
    );
  }

  const handleUpdate = () => {
    navigate(`/action-form?id=${action.id}`);
  };

  const handleDelete = async () => {
    if (!user) return;
    try {
      await deleteAction(user.uid, action.id);
      logDeleteAction();
      deleteActionEvent(action.id);
    } catch (e) {
      console.log("Failed to delete action", e);
    }
  };

  const onOkDialog = async () => {
    await handleDelete();
    closeDialog();
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={3}>
              <Typography component="legend" variant="subtitle2">
                場所
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="legend" variant="body1">
                {action.location}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Typography component="legend" variant="subtitle2" sx={{ mt: 1 }}>
                行動
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="legend" variant="body1" sx={{ mt: 1 }}>
                {action.action}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Typography component="legend" variant="subtitle2" sx={{ mt: 1 }}>
                違和感
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="legend" variant="body1" sx={{ mt: 1 }}>
                {action.uncomfortable}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Button variant="outlined" size="medium" onClick={handleUpdate}>
            編集
          </Button>
          <Button sx={{ mt: 2 }} variant="outlined" size="medium" onClick={openDialog}>
            削除
          </Button>
        </Box>
      </Box>
      <ConfirmDialog
        title="行動ログの削除"
        message={`${toMDString(date)}の行動ログを削除しますか？`}
        ok="削除"
        open={open}
        handleOk={onOkDialog}
        handleCancel={closeDialog}
      />
    </>
  );
}
