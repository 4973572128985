import { useState, createContext, useContext } from "react";
import { ActionDocRef, SymptomDocRef } from "../models/models";
import { findActionsOfSpan } from "../services/actionService";
import { findSymptomsOfSpan } from "../services/symptomService";
import { useUser } from "./AuthContext";

export type EventItems = {
  symptoms: SymptomDocRef[];
  actions: ActionDocRef[];
};

export type EventContextType = {
  symptoms: SymptomDocRef[];
  actions: ActionDocRef[];
  loadEventData: (start: Date, end: Date) => Promise<void>;
  deleteSymptomEvent: (symptomId: string) => void;
  deleteActionEvent: (symptomId: string) => void;
};

export const EventContext = createContext<EventContextType>({
  symptoms: [],
  actions: [],
  loadEventData: async () => {},
  deleteSymptomEvent: async () => {},
  deleteActionEvent: async () => {},
});

const EventContextComp = ({ children }: any) => {
  const [event, setEvent] = useState<EventItems>({ symptoms: [], actions: [] });
  const { user } = useUser();

  const loadEventData = async (start: Date, end: Date) => {
    if (!user) {
      throw new Error("user is null");
    }
    const [symptoms, actions] = await Promise.all([
      findSymptomsOfSpan(user.uid, start, end),
      findActionsOfSpan(user.uid, start, end),
    ]);
    // console.log("loadEventData: ", symptoms, actions);
    setEvent({ symptoms, actions });
  };

  const deleteSymptomEvent = async (id: string) => {
    const index = event.symptoms.findIndex((x) => x.id === id);
    if (index < 0) {
      throw Error("Symptom is not found");
    }
    setEvent({ symptoms: event.symptoms.splice(index, 1), actions: event.actions });
  };
  const deleteActionEvent = async (id: string) => {
    const index = event.actions.findIndex((x) => x.id === id);
    if (index < 0) {
      throw Error("Action is not found");
    }
    setEvent({ symptoms: event.symptoms, actions: event.actions.splice(index, 1) });
  };

  return (
    <EventContext.Provider
      value={{
        symptoms: event.symptoms,
        actions: event.actions,
        loadEventData,
        deleteSymptomEvent,
        deleteActionEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEventData = () => useContext<EventContextType>(EventContext);

export default EventContextComp;
