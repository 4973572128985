import { Box, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { ActionDocRef, Symptom, SymptomDocRef } from "../models/models";
import SymptomDetails from "./symptomDetails";
import ActionDetails from "./actionDetails";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { toMDString } from "../utils/dateUtil";

export type symptom = Symptom & {
  id: string | null;
  temperatureDisabled: boolean;
};

export type Props = {
  date: Date;
  symptom: SymptomDocRef | null;
  action: ActionDocRef | null;
  defaultView: "symptom" | "action" | null;
  onClickClose: () => void;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DateDetailsDrawer = ({ date, symptom, action, defaultView, onClickClose }: Props) => {
  // console.log("details", date, symptom, action, defaultView);

  const tabIndex = React.useCallback((): number => {
    return defaultView === "action" || (symptom === null && action !== null) ? 1 : 0;
  }, [symptom, action, defaultView]);
  const [value, setValue] = React.useState(tabIndex());

  React.useEffect(() => {
    setValue(tabIndex());
  }, [tabIndex]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        // sx={{ borderBottom: "1px solid #ccc" }}
        // sx={{ backgroundColor: "#388e3c", color: "#ffffff" }}
      >
        <Grid item sx={{ pt: 1.5, pl: 3 }}>
          <Box sx={{ bgcolor: "primary.main", color: "#ffffff", px: 5, borderRadius: 5 }}>
            <Typography component="legend" variant="subtitle2">
              {toMDString(date)}
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{ pr: 1.5 }}>
          <IconButton aria-label="close" size="medium" onClick={onClickClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="date details tabs">
          <Tab iconPosition="start" label="体調ログ" {...a11yProps(0)} />
          {/* <Tab icon={<FavoriteIcon />} iconPosition="start" label="体調ログ" {...a11yProps(0)} /> */}
          <Tab iconPosition="start" label="行動ログ" {...a11yProps(1)} />
          {/* <Tab icon={<DirectionsWalkIcon />} iconPosition="start" label="行動ログ" {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SymptomDetails date={date} symptom={symptom} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ActionDetails date={date} action={action} />
      </TabPanel>

      {/* <Box sx={{ m: 2, mt: 1 }}>
        <br />
      </Box> */}
    </>
  );
};

export default React.memo(DateDetailsDrawer);
