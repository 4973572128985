import { Action, ActionDocRef } from "../models/models";
import { nextMonthFirst, prevMonthFirst } from "../utils/dateUtil";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  where,
  query,
  getDocs,
  FirestoreDataConverter,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";

const userCol = "users";
const actCol = "actions";

const actionConverter: FirestoreDataConverter<ActionDocRef> = {
  toFirestore: (data) => {
    return data;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return { ...data, id: snapshot.id, date: data.date.toDate() } as ActionDocRef;
  },
};

export async function addAction(userId: string, newAction: Action) {
  try {
    await addDoc(collection(db, userCol, userId, actCol), newAction);
  } catch (e) {
    console.error("Error adding action: ", e);
    throw e;
  }
}

export async function updateAction(userId: string, newAction: ActionDocRef) {
  try {
    const docRef = doc(db, userCol, userId, actCol, newAction.id).withConverter(actionConverter);
    await updateDoc(docRef, newAction);
  } catch (e) {
    console.error("Error updating action: ", e);
    throw e;
  }
}

export async function getAction(userId: string, id: string): Promise<ActionDocRef | null> {
  const docRef = doc(db, userCol, userId, actCol, id).withConverter(actionConverter);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as ActionDocRef;
  } else {
    return null;
  }
}
export async function deleteAction(userId: string, actionId: string) {
  try {
    await deleteDoc(doc(db, userCol, userId, actCol, actionId));
  } catch (e) {
    console.error("Error deleting action: ", e);
    throw e;
  }
}

export async function findActionByDate(userId: string, date: Date): Promise<ActionDocRef | null> {
  const col = collection(db, userCol, userId, actCol).withConverter(actionConverter);
  const q = query(col, where("date", "==", date));

  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => doc.data());
  if (list.length === 0) {
    return null;
  }
  if (list.length > 1) {
    console.log(`Count of actions of same data is ${list.length}`);
  }
  return list[0] as ActionDocRef;
}

export async function findActionsOfSpan(userId: string, start: Date, end: Date): Promise<ActionDocRef[]> {
  const col = collection(db, userCol, userId, actCol).withConverter(actionConverter);
  const q = query(col, where("date", ">=", start), where("date", "<", end));

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data());
}

export async function findActionsOfMonth(
  userId: string,
  date: Date,
  prev: number,
  next: number
): Promise<ActionDocRef[]> {
  const col = collection(db, userCol, userId, actCol).withConverter(actionConverter);
  const q = query(
    col,
    where("date", ">=", prevMonthFirst(date, prev)),
    where("date", "<", nextMonthFirst(date, next))
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data());
}
