import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Calendar from "./contents/calendar";
import Others from "./contents/others";
import ActionForm from "./contents/actionForm";
import Login from "./contents/login";
import AuthContextComp from "./context/AuthContext";
import PrivateRoute from "./layout/privateRoute";
import PublicRoute from "./layout/publicRoute";
import EventContextComp from "./context/EventContext";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfService from "./pages/termsOfService";
import MainLayout from "./layout/mainLayout";
import HowToUse from "./pages/howToUse";
import SymptomForm from "./contents/symptomForm";

ReactDOM.render(
  <BrowserRouter>
    <AuthContextComp>
      <Routes>
        <Route element={<App />}>
          <Route path="/login" element={<PublicRoute />}>
            <Route index element={<Login />} />
          </Route>
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route element={<MainLayout />}>
              <Route
                path=""
                element={
                  <EventContextComp>
                    <Calendar />
                  </EventContextComp>
                }
              />
              <Route path="symptom-form" element={<SymptomForm />} />
              <Route path="action-form" element={<ActionForm />} />
              <Route path="others" element={<Others />} />
              <Route path="how-to-use" element={<HowToUse />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </AuthContextComp>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
