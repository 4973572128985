import SignInScreen from "../firebase/firebaseUi";
import { User } from "../models/models";
import { createUser } from "../services/userService";
import { Box, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { Link } from "react-router-dom";
import React from "react";
import { logCreateUser, logError, logLogin, logScreenView } from "../services/EventLogger";
import { ErrorType } from "../error";

// 画像サイトからDL
// https://www.pexels.com/photo/food-summer-desk-notebook-8534221/
import Image from "../img/pexels-anna-nekrashevich-8534221.jpg";

const styles = {
  boxContainer: {
    backgroundImage: `url(${Image})`,
    // height: "100vh",
    backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    // backgroundSize: "contain",
    backgroundSize: "100% auto",
    paddingBottom: "calc(100%*516.766/344.516)", // 100%*画像の高さ/画像の幅
    // backgroundPosition: "0 -200px 0 0",
    // backgroundPosition: "top 200px",
  },
};

export default function Login() {
  const signInSuccessWithAuthResult = (authResult: any, redirectUrl: any): boolean => {
    const {
      additionalUserInfo: { isNewUser },
      user,
    } = authResult;
    if (isNewUser) {
      createUser(user.uid, {} as User)
        .then(() => {
          logCreateUser();
        })
        .catch((e) => {
          console.log("Failed to create new user on first login", e);
        });
    }
    logLogin();
    return false;
  };

  const signInFailure = (error: firebaseui.auth.AuthUIError) => {
    logError(ErrorType.LoginError);
    console.log("Failed to login", error);
  };

  React.useEffect(() => {
    logScreenView("index", "Login");
  }, []);

  return (
    <>
      <Box style={styles.boxContainer} sx={{ position: "relative", color: "#505050", mb: 13 }}>
        <Typography variant="h5" sx={{ position: "absolute", top: 70, left: 30 }}>
          風邪ログで
          <br />
          自分の体調を管理しよう
        </Typography>
        <Box
          sx={{
            position: "absolute",
            bottom: -80,
            right: 0,
            m: 1,
            // mb: 9,
            pt: 1.5,
            // px: 3,
            // maxWidth: 250,
            bgcolor: "#ffffffd0",
            borderRadius: 1,
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            ログイン
          </Typography>
          <SignInScreen callbacks={{ signInSuccessWithAuthResult, signInFailure }} />
          <Box sx={{ px: 2 }}>
            <Typography variant="caption">
              登録することで、<Link to="/terms">サービス利用規約</Link>と<br />
              <Link to="/privacy">プライバシーポリシー</Link>に同意すること
              <br />
              とします。
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ textAlign: "center", color: "#505050", pb: 1 }}>
        <Box>
          <FavoriteIcon
            sx={{
              color: "#ff6d75",
              fontSize: 35,
            }}
          />
          <Typography variant="subtitle1">その日の体調をかんたんに記録</Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <DirectionsWalkIcon sx={{ fontSize: 35 }} />
          <Typography variant="subtitle1">体調が悪くなった直前の行動を記録</Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <DateRangeIcon
            sx={{
              color: "#689f38",
              fontSize: 35,
            }}
          />
          <Typography variant="subtitle1">体調と行動の記録をカレンダーで管理</Typography>
        </Box>
      </Box>

      <Box sx={{ textAlign: "center", color: "#505050", bgcolor: "#f5f5f5", mt: 5, px: 4, py: 5 }}>
        <Box>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ color: "#2f2f2f" }}>
            どんなメリットがあるの？
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            風邪の予防に役立つ
          </Typography>
          <Typography variant="body2">
            体調が悪くなった直前の行動を見返し、自分がどんなときに風邪を引くのかわかるようになる。
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            体調の改善に役立つ
          </Typography>
          <Typography variant="body2">
            体調の変化を見返し、何をすれば風邪が早く治りやすいかわかるようになる。
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            スケジュール調整に役立つ
          </Typography>
          <Typography variant="body2">
            体調がよくなるまでの期間を見返し、風邪が治るまでどのくらいかかるかわかるようになる。
          </Typography>
        </Box>
      </Box>

      <Box sx={{ color: "#505050", mt: 2, py: 3 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ color: "#2f2f2f" }}>
            はじめかた
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">1. ログインします。</Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body1">2. 今日の体調を記録します。</Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body1">3. 前日の行動を記録します。</Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body1">4. 記録した体調や行動が見える化されます。</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ textAlign: "center", color: "#505050", pt: 2, pb: 1, px: 3 }}>
        <Typography variant="caption">
          ※風邪ログは体調や行動を記録するアプリです。直接体調に影響を及ぼしたり、医学的なアドバイスをすることはありません。
        </Typography>
      </Box>

      <Box sx={{ textAlign: "center", color: "#505050", py: 2 }}>
        <Typography variant="caption">©isolity</Typography>
      </Box>
    </>
  );
}
