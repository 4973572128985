import React from "react";
import { styled } from "@mui/material/styles";
import { Drawer } from "@mui/material";
import DateDetailsDrawer from "../components/dateDetailsDrawer";
import CalendarComponent, { DateItem } from "../components/calendarComponent";
import { logScreenView } from "../services/EventLogger";

const drawerHeight = 320;
// calc(100% - ${drawerTopHeight}px)

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  //   height: `calc(100% - ${drawerHeight}px)`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginBottom: drawerHeight + 12,
    // marginBottom: `calc(100% - ${drawerTopHeight}px + 300px)`,
  }),
}));

export default function Calendar() {
  const [selectedItem, setDateItem] = React.useState<DateItem | null>(null);

  const onClickDate = React.useCallback((item: DateItem) => {
    setDateItem(item);
    openDrawer();
  }, []);

  const [open, setOpen] = React.useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = React.useCallback(() => setOpen(false), []);

  React.useEffect(() => {
    logScreenView("calendar", "Calendar");
  }, []);

  return (
    <>
      <Main open={open}>
        <CalendarComponent onClickDate={onClickDate} />
      </Main>
      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            height: drawerHeight,
            // height: `calc(100% - ${drawerTopHeight}px)`,
            // borderTopColor: "black",
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            mb: 7,
            // borderColor: "#689f38",
            // borderWidth: 2,
          },
        }}
        variant="persistent"
        anchor="bottom"
        open={open}
      >
        {selectedItem !== null && (
          <DateDetailsDrawer
            date={selectedItem.date}
            defaultView={selectedItem.eventType}
            symptom={selectedItem.symptom}
            action={selectedItem.action}
            onClickClose={closeDrawer}
          />
        )}
      </Drawer>
    </>
  );
}
