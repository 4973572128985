// import jaLocale from "@fullcalendar/core/locales/ja";
// import { format } from "date-fns";
import { addDays as fnsAddDays } from "date-fns";

export function today(): Date {
  var d = new Date();
  d.setUTCHours(0, 0, 0, 0);
  return d;
}

export function addDays(date: Date, days: number): Date {
  return fnsAddDays(date, days);
}

export function thisMonthFirst(date: Date): Date {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
}

export function prevMonthFirst(date: Date, span: number = 1): Date {
  return new Date(date.getUTCFullYear(), date.getUTCMonth() - span, 1);
}

export function nextMonthFirst(date: Date, span: number = 1): Date {
  return new Date(date.getUTCFullYear(), date.getUTCMonth() + span, 1);
}

export function isSameDate(a: Date, b: Date): boolean {
  return (
    a.getUTCFullYear() === b.getUTCFullYear() &&
    a.getUTCMonth() === b.getUTCMonth() &&
    a.getUTCDate() === b.getUTCDate()
  );
}

export function toMDString(date: Date, separator: string = "/"): string {
  return (date.getUTCMonth() + 1).toString() + separator + date.getUTCDate().toString();
  // return (
  //   (date.getUTCMonth() + 1).toString().padStart(2, "0") + separator + date.getUTCDate().toString().padStart(2, "0")
  // );
}

/**
 * Convert date object into "yyyy-MM-dd" string in UTC
 * @param date date
 * @returns string
 */
export function toYMDString(date: Date): string {
  // date-fns の format() はUTC非対応。
  // return format(date, "yyyy-MM-dd");
  // toISOStringはUTCで文字列化される。
  return date.toISOString().split("T")[0];
}

// export function toDateString(date: Date, format: string): string {
//   return format(date, format, { locale: jaLocale });
// }
