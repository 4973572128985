import { Box, Paper } from "@mui/material";
import { Outlet } from "react-router-dom";
import MainMenu from "./mainMenu";
import AdBanner from "../components/adBanner";

export default function MainLayout() {
  return (
    <>
      <Box sx={{ pb: 15 }}>
        <Outlet />
      </Box>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 2000, // drawer: 1200, https://mui.com/customization/z-index/
        }}
        elevation={3}
      >
        <AdBanner />
        <MainMenu />
      </Paper>
    </>
  );
}
