import * as React from "react";
import { Button, Box, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Action, ActionDocRef } from "../models/models";
import { today, toMDString } from "../utils/dateUtil";
import { getAction, findActionByDate, addAction, updateAction } from "../services/actionService";
import { useUser } from "../context/AuthContext";
import { logAddAction, logScreenView, logUpdateAction } from "../services/EventLogger";

export type NewActionForm = Action & {
  id: string | null;
};

export default function ActionForm() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dateStr = params.get("date");
  const id = params.get("id");
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [formDate, setNewActionForm] = React.useState<NewActionForm>({
    id: null,
    date: dateStr ? new Date(dateStr) : today(),
    location: "",
    action: "",
    uncomfortable: "",
  });

  const { user } = useUser();

  const loadDate = React.useCallback(async () => {
    if (!user) return;
    // console.log("id:", id, "date:", dateStr);
    if (id) {
      const action = await getAction(user.uid, id);
      // console.log(action);
      if (action) {
        setNewActionForm(toNewActionForm(action));
        setIsEditMode(true);
      }
    } else if (dateStr) {
      const date = new Date(dateStr);
      const action = await findActionByDate(user?.uid, date);
      // console.log(action);
      if (action) {
        setNewActionForm(toNewActionForm(action));
        setIsEditMode(true);
      }
    } else {
      const action = await findActionByDate(user?.uid, today());
      // console.log(action);
      if (action) {
        setNewActionForm(toNewActionForm(action));
        setIsEditMode(true);
      }
    }
  }, [id, dateStr, user]);

  const toNewActionForm = (action: ActionDocRef): NewActionForm => {
    return {
      id: action.id,
      date: action.date,
      location: action.location,
      action: action.action,
      uncomfortable: action.uncomfortable,
    } as NewActionForm;
  };

  const toNewAction = (action: NewActionForm): ActionDocRef => {
    return {
      id: action.id || "",
      date: action.date,
      location: action.location.trim(),
      action: action.action.trim(),
      uncomfortable: action.uncomfortable.trim(),
    } as ActionDocRef;
  };

  React.useEffect(() => {
    logScreenView("action-form", "ActionForm");
    loadDate();
  }, [loadDate]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name as keyof typeof formDate;
    setNewActionForm({ ...formDate, [name]: target.value });
  };

  const handleSave = async () => {
    // console.log("save:", "isEditMode", isEditMode, formDate);
    if (!user) return;

    const action = toNewAction(formDate);
    if (isEditMode) {
      try {
        await updateAction(user.uid, action);
        logUpdateAction();
        navigate("/");
      } catch (e) {
        console.error("Failed to update action", e);
      }
    } else {
      try {
        await addAction(user.uid, action);
        logAddAction();
        navigate("/");
      } catch (e) {
        console.error("Failed to add action", e);
      }
    }
  };

  return (
    <>
      <Box sx={{ mx: 2, mb: 3 }}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
            // "& .MuiTextField-root": { m: 1, width: "25ch" },
            my: 1,
          }}
          noValidate
          autoComplete="off"
        >
          <Box display="flex" justifyContent="center">
            <Typography component="legend" variant="h6">
              {toMDString(formDate.date)}の行動
            </Typography>
          </Box>
          <Typography component="legend" variant="subtitle1">
            場所
          </Typography>
          <Box display="flex" justifyContent="center">
            <TextField
              multiline
              fullWidth
              name="location"
              placeholder="例：外"
              value={formDate.location}
              onChange={handleChange}
              inputProps={{ maxLength: 255 }}
            />
          </Box>
          <Typography component="legend" variant="subtitle1">
            行動
          </Typography>
          <Box display="flex" justifyContent="center">
            <TextField
              multiline
              fullWidth
              name="action"
              placeholder="例：花見"
              value={formDate.action}
              onChange={handleChange}
              inputProps={{ maxLength: 255 }}
            />
          </Box>
          <Typography component="legend" variant="subtitle1">
            違和感
          </Typography>
          <Box display="flex" justifyContent="center">
            <TextField
              multiline
              fullWidth
              name="uncomfortable"
              placeholder="例：夜風に当たって寒かった。"
              value={formDate.uncomfortable}
              onChange={handleChange}
              inputProps={{ maxLength: 255 }}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <Button variant="contained" size="large" sx={{ px: 5, borderRadius: 5 }} onClick={handleSave}>
                {isEditMode ? "更新する" : "追加する"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
