import * as React from "react";
import { Button, Box, InputAdornment, Input, TextField, IconButton, Switch } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Symptom, SymptomDocRef } from "../models/models";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import { today, toMDString } from "../utils/dateUtil";
import { getSymptom, findSymptomByDate, addSymptom, updateSymptom } from "../services/symptomService";
import ConditionRating from "../components/conditionRating";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useUser } from "../context/AuthContext";
import { logAddSymptom, logScreenView, logUpdateSymptom } from "../services/EventLogger";

const defaultTemperature = 37.5;

export type NewSymptomForm = Symptom & {
  id: string | null;
  temperatureValue: number;
  temperatureDisabled: boolean;
};

export default function SymptomForm() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dateStr = params.get("date");
  const id = params.get("id");
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [formDate, setNewSymptomForm] = React.useState<NewSymptomForm>({
    id: null,
    date: dateStr ? new Date(dateStr) : today(),
    throatRating: 5,
    noseRating: 5,
    headRating: 5,
    bodyRating: 5,
    temperature: null,
    temperatureValue: defaultTemperature,
    temperatureDisabled: true,
    note: "",
  });

  const { user } = useUser();

  const loadDate = React.useCallback(async () => {
    if (!user) return;
    // console.log("id:", id, "date:", dateStr);
    if (id) {
      const symptom = await getSymptom(user.uid, id);
      // console.log(symptom);
      if (symptom) {
        setNewSymptomForm(toNewSymptomForm(symptom));
        setIsEditMode(true);
      }
    } else if (dateStr) {
      const date = new Date(dateStr);
      const symptom = await findSymptomByDate(user.uid, date);
      // console.log(symptom);
      if (symptom) {
        setNewSymptomForm(toNewSymptomForm(symptom));
        setIsEditMode(true);
      }
    } else {
      const symptom = await findSymptomByDate(user.uid, today());
      // console.log(symptom);
      if (symptom) {
        setNewSymptomForm(toNewSymptomForm(symptom));
        setIsEditMode(true);
      }
    }
  }, [id, dateStr, user]);

  const toNewSymptomForm = (symptom: SymptomDocRef): NewSymptomForm => {
    return {
      id: symptom.id,
      date: symptom.date,
      throatRating: symptom.throatRating,
      noseRating: symptom.noseRating,
      headRating: symptom.headRating,
      bodyRating: symptom.bodyRating,
      temperatureValue: symptom.temperature || 37.5,
      temperatureDisabled: symptom.temperature === null,
      note: symptom.note,
    } as NewSymptomForm;
  };

  const toNewSymptom = (symptom: NewSymptomForm): SymptomDocRef => {
    return {
      id: symptom.id || "",
      date: symptom.date,
      throatRating: symptom.throatRating,
      noseRating: symptom.noseRating,
      headRating: symptom.headRating,
      bodyRating: symptom.bodyRating,
      temperature: symptom.temperatureDisabled ? null : symptom.temperatureValue,
      note: symptom.note.trim(),
    } as SymptomDocRef;
  };

  React.useEffect(() => {
    logScreenView("symptom-form", "SymptomForm");
    loadDate();
  }, [loadDate]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name as keyof typeof formDate;
    setNewSymptomForm({ ...formDate, [name]: target.value });
  };
  const handleChangeRating = (event: React.SyntheticEvent<Element, Event>, value: number | null) => {
    const target = event.target as HTMLInputElement;
    const name = target.name as keyof typeof formDate;
    setNewSymptomForm({ ...formDate, [name]: Number(target.value) });
  };
  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSymptomForm({ ...formDate, temperatureDisabled: !event.target.checked });
  };
  const updateTemperature = (temperature: number) => {
    if (isNaN(temperature)) {
      temperature = defaultTemperature;
    }
    setNewSymptomForm({
      ...formDate,
      temperatureValue: Math.trunc(temperature * 10) / 10,
      temperatureDisabled: false,
    });
  };
  const enableTemperature = () => {
    if (formDate.temperatureDisabled) {
      setNewSymptomForm({
        ...formDate,
        temperatureDisabled: false,
      });
    }
  };

  const handleSave = async () => {
    // console.log("save:", "isEditMode", isEditMode, formDate);
    if (!user) return;

    const symptom = toNewSymptom(formDate);
    if (isEditMode) {
      try {
        await updateSymptom(user.uid, symptom);
        logUpdateSymptom();
        navigate("/");
      } catch (e) {
        console.error("Failed to update symptom", e);
      }
    } else {
      try {
        await addSymptom(user.uid, symptom);
        logAddSymptom(symptom);
        navigate("/");
      } catch (e) {
        console.error("Failed to add symptom", e);
      }
    }
  };

  return (
    <>
      <Box sx={{ mx: 2, mb: 3 }}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
            // "& .MuiTextField-root": { m: 1, width: "25ch" },
            my: 1,
          }}
          noValidate
          autoComplete="off"
        >
          <Box display="flex" justifyContent="center">
            <Typography component="legend" variant="h6">
              {toMDString(formDate.date)}の体調
            </Typography>
          </Box>
          <Box sx={{ bgcolor: "#dfdfdf", p: 0.8, px: 1, mt: 1, mb: 1 }}>
            <Typography component="legend" variant="subtitle2" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
              基本情報
            </Typography>
          </Box>
          <Typography component="legend" variant="subtitle1" sx={{ mb: -0.5 }}>
            のど
          </Typography>
          <Box display="flex" justifyContent="center">
            <ConditionRating
              name="throatRating"
              value={formDate.throatRating}
              onChange={handleChangeRating}
              getLabelText={(value: number) => `${value} Heart${value !== 1 ? "s" : ""}`}
              sx={{ fontSize: 40 }}
              icon={<FavoriteIcon fontSize="inherit" style={{ marginLeft: "6px", marginRight: "6px" }} />}
              emptyIcon={
                <FavoriteBorderIcon fontSize="inherit" style={{ marginLeft: "6px", marginRight: "6px" }} />
              }
            />
          </Box>
          <Typography component="legend" variant="subtitle1" sx={{ mb: -0.5 }}>
            鼻
          </Typography>
          <Box display="flex" justifyContent="center">
            <ConditionRating
              name="noseRating"
              value={formDate.noseRating}
              onChange={handleChangeRating}
              getLabelText={(value: number) => `${value} Heart${value !== 1 ? "s" : ""}`}
              sx={{ fontSize: 40 }}
              icon={<FavoriteIcon fontSize="inherit" style={{ marginLeft: "6px", marginRight: "6px" }} />}
              emptyIcon={
                <FavoriteBorderIcon fontSize="inherit" style={{ marginLeft: "6px", marginRight: "6px" }} />
              }
            />
          </Box>
          <Typography component="legend" variant="subtitle1" sx={{ mb: -0.5 }}>
            頭
          </Typography>
          <Box display="flex" justifyContent="center">
            <ConditionRating
              name="headRating"
              value={formDate.headRating}
              onChange={handleChangeRating}
              getLabelText={(value: number) => `${value} Heart${value !== 1 ? "s" : ""}`}
              sx={{ fontSize: 40 }}
              icon={<FavoriteIcon fontSize="inherit" style={{ marginLeft: "6px", marginRight: "6px" }} />}
              emptyIcon={
                <FavoriteBorderIcon fontSize="inherit" style={{ marginLeft: "6px", marginRight: "6px" }} />
              }
            />
          </Box>
          <Typography component="legend" variant="subtitle1" sx={{ mb: -0.5 }}>
            体
          </Typography>
          <Box display="flex" justifyContent="center">
            <ConditionRating
              name="bodyRating"
              value={formDate.bodyRating}
              onChange={handleChangeRating}
              getLabelText={(value: number) => `${value} Heart${value !== 1 ? "s" : ""}`}
              sx={{ fontSize: 40 }}
              icon={<FavoriteIcon fontSize="inherit" style={{ marginLeft: "6px", marginRight: "6px" }} />}
              emptyIcon={
                <FavoriteBorderIcon fontSize="inherit" style={{ marginLeft: "6px", marginRight: "6px" }} />
              }
            />
            {/* <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 1 }}>
                            <Typography component="legend" variant="subtitle1"
                                sx={{ mt: 0.6 }}>体温 は？</Typography>
                            <Switch checked={!newSymptomForm.temperatureDisabled}
                                onClick={() => {
                                    setNewSymptomForm({
                                        ...newSymptomForm,
                                        temperatureDisabled: !newSymptomForm.temperatureDisabled
                                    })
                                }} />
                        </Box> */}
          </Box>
          <Box sx={{ bgcolor: "#dfdfdf", p: 0.8, px: 1, mt: 2, mb: 1 }}>
            <Typography component="legend" variant="subtitle2" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
              補足情報
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography component="legend" variant="subtitle1">
                体温
              </Typography>
              <Switch
                checked={!formDate.temperatureDisabled}
                onChange={handleChangeSwitch}
                sx={{ mt: -0.6 }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Box>
            <Box sx={{ mt: -0.6 }}>
              <IconButton
                aria-label="down"
                onClick={() => {
                  updateTemperature(formDate.temperatureValue - 0.1);
                }}
              >
                <ArrowDownwardIcon />
              </IconButton>
              <IconButton
                aria-label="up"
                size="medium"
                onClick={() => {
                  updateTemperature(formDate.temperatureValue + 0.1);
                }}
              >
                <ArrowUpwardIcon />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <ThermostatIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <Input
              id="standard-adornment-weight"
              name="temperatureValue"
              // sx={{ width: 140 }}
              size="medium"
              disabled={formDate.temperatureDisabled}
              value={formDate.temperatureValue}
              onClick={(event) => {
                enableTemperature();
              }}
              onChange={handleChange}
              onBlur={() => {
                updateTemperature(formDate.temperatureValue);
              }}
              endAdornment={<InputAdornment position="end">℃</InputAdornment>}
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                style: { textAlign: "center" },
                "aria-label": "weight",
              }}
            />
          </Box>
          <Typography component="legend" variant="subtitle1" sx={{ mt: 1 }}>
            症状メモ
          </Typography>
          <Box display="flex" justifyContent="center">
            <TextField
              // id="outlined-multiline-flexible"
              // label="症状メモ"
              multiline
              fullWidth
              // maxRows={4}
              name="note"
              placeholder="例：朝から鼻水が止まらない。"
              value={formDate.note}
              onChange={handleChange}
              inputProps={{ maxLength: 255 }}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Button variant="contained" size="large" sx={{ px: 5, borderRadius: 5 }} onClick={handleSave}>
                {isEditMode ? "更新する" : "追加する"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
