import { useState, useEffect, createContext, useContext } from "react";
import firebase, { auth } from "../firebase/firebase";

export type AuthContextType = {
  user: firebase.User | null;
  loadingUser: boolean;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  loadingUser: true,
});

const AuthContextComp = ({ children }: any) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [loadingUser, setLoadingUser] = useState(true); // Helpful, to update the UI accordingly.

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          // User is signed in.
          // const { uid, displayName, email, photoURL } = user;
          // console.log("Logged in user");
          // console.log(user);
          setUser(user);
          // You could also look for the user doc in your Firestore (if you have one):
          // const userDoc = await firebase.firestore().doc(`users/${uid}`).get()
          // setUser({ uid, displayName, email, photoURL });
        } else setUser(null);
      } catch (error) {
        // Most probably a connection error. Handle appropriately.
      } finally {
        setLoadingUser(false);
      }
    });
  }, []);

  return <AuthContext.Provider value={{ user, loadingUser }}>{children}</AuthContext.Provider>;
};

// Custom hook that shorthands the context!
export const useUser = () => useContext<AuthContextType>(AuthContext);

export default AuthContextComp;
