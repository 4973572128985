// FirebaseUIはFirebase v9に対応していないのでv8を使う。
// そのため、compatのappを使い、authを加える。
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";
import { firebaseConfig } from "./config";
import { getAnalytics } from "firebase/analytics";

export default firebase;

export const firebaseApp = firebase.initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);

export const db = getFirestore();

export const auth = firebaseApp.auth();

export const analytics = getAnalytics(firebaseApp);
