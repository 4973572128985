import { Symptom, SymptomDocRef } from "../models/models";
import { nextMonthFirst, prevMonthFirst } from "../utils/dateUtil";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  where,
  query,
  getDocs,
  FirestoreDataConverter,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";

const userCol = "users";
const symCol = "symptoms";

// Firestore error code
// https://firebase.google.cn/docs/reference/js/v8/firebase.firestore#firestoreerrorcode

const symptomConverter: FirestoreDataConverter<SymptomDocRef> = {
  toFirestore: (data) => {
    return data;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return { ...data, id: snapshot.id, date: data.date.toDate() } as SymptomDocRef;
  },
};

export async function addSymptom(userId: string, newSymptom: Symptom) {
  try {
    await addDoc(collection(db, userCol, userId, symCol), newSymptom);
  } catch (e) {
    console.error("Error adding symptom: ", e);
    throw e;
  }
}

export async function updateSymptom(userId: string, newSymptom: SymptomDocRef) {
  try {
    const docRef = doc(db, userCol, userId, symCol, newSymptom.id).withConverter(symptomConverter);
    await updateDoc(docRef, newSymptom);
  } catch (e) {
    console.error("Error updating symptom: ", e);
    throw e;
  }
}

export async function getSymptom(userId: string, id: string): Promise<SymptomDocRef | null> {
  const docRef = doc(db, userCol, userId, symCol, id).withConverter(symptomConverter);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as SymptomDocRef;
  } else {
    return null;
  }
}

export async function deleteSymptom(userId: string, symptomId: string) {
  try {
    await deleteDoc(doc(db, userCol, userId, symCol, symptomId));
  } catch (e) {
    console.error("Error deleting symptom: ", e);
    throw e;
  }
}

export async function findSymptomByDate(userId: string, date: Date): Promise<SymptomDocRef | null> {
  const col = collection(db, userCol, userId, symCol).withConverter(symptomConverter);
  const q = query(col, where("date", "==", date));

  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => doc.data());
  if (list.length === 0) {
    return null;
  }
  if (list.length > 1) {
    console.log(`Count of symptoms of same data is ${list.length}`);
  }
  return list[0] as SymptomDocRef;
}

export async function findSymptomsOfSpan(userId: string, start: Date, end: Date): Promise<SymptomDocRef[]> {
  const col = collection(db, userCol, userId, symCol).withConverter(symptomConverter);
  const q = query(col, where("date", ">=", start), where("date", "<", end));

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data());
}

export async function findSymptomsOfMonth(
  userId: string,
  date: Date,
  prev: number,
  next: number
): Promise<SymptomDocRef[]> {
  const col = collection(db, userCol, userId, symCol).withConverter(symptomConverter);
  const q = query(
    col,
    where("date", ">=", prevMonthFirst(date, prev)),
    where("date", "<", nextMonthFirst(date, next))
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data());
}
