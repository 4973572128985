import * as React from "react";

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

export default function AdBanner() {
  const adClient = process.env.REACT_APP_AD_CLIENT ?? "";
  const adSlot = process.env.REACT_APP_AD_SLOT ?? "";
  const isDev = process.env.NODE_ENV === "development";

  React.useEffect(() => {
    if (window.adsbygoogle && !isDev) {
      window.adsbygoogle.push({});
    }
  }, [isDev]);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", width: "100%", height: "50px", ...(isDev && { backgroundColor: "grey" }) }}
      data-ad-client={adClient}
      data-ad-slot={adSlot}
    ></ins>
  );
}
