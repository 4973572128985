import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { SymptomDocRef } from "../models/models";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ConditionRating from "./conditionRating";
import { useNavigate } from "react-router-dom";
import { addDays, today, toMDString, toYMDString } from "../utils/dateUtil";
import { deleteSymptom } from "../services/symptomService";
import { useUser } from "../context/AuthContext";
import { useEventData } from "../context/EventContext";
import ConfirmDialog from "./confirmDialog";
import React from "react";
import { isBefore, isAfter, subDays } from "date-fns";
import { logDeleteSymptom } from "../services/EventLogger";

export type Props = {
  date: Date;
  symptom: SymptomDocRef | null;
};

export default function SymptomDetails({ date, symptom }: Props) {
  const navigate = useNavigate();
  const { deleteSymptomEvent } = useEventData();
  const { user } = useUser();

  const [open, setOpen] = React.useState(false);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const handleAdd = () => {
    navigate(`/symptom-form?date=${toYMDString(date)}`);
  };

  const canAddSymptom = (): boolean => {
    const today_ = today();
    const tomorrow = addDays(today_, 1);
    return isBefore(date, tomorrow) && isAfter(date, subDays(today_, 32));
  };

  if (symptom === null) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography component="legend" variant="body1">
              体調が記録されていません。
            </Typography>
            <br />
            {!canAddSymptom() && (
              <Typography component="legend" variant="body1">
                明日以降、あるいは1ヶ月より前の体調は記録できません。
              </Typography>
            )}
          </Box>
          {canAddSymptom() && (
            <Button variant="outlined" size="medium" onClick={handleAdd}>
              記録
            </Button>
          )}
        </Box>
      </>
    );
  }

  const handleUpdate = () => {
    navigate(`/symptom-form?id=${symptom.id}`);
  };

  const handleDelete = async () => {
    if (!user) return;
    try {
      await deleteSymptom(user.uid, symptom.id);
      logDeleteSymptom();
      deleteSymptomEvent(symptom.id);
    } catch (e) {
      console.log("Failed to delete symptom", e);
    }
  };

  const onOkDialog = async () => {
    await handleDelete();
    closeDialog();
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={3}>
              <Typography component="legend" variant="subtitle2">
                のど
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <ConditionRating
                name="throatRating"
                value={symptom.throatRating}
                getLabelText={(value: number) => `${value} Heart${value !== 1 ? "s" : ""}`}
                sx={{ fontSize: 25, mt: 1, mb: 1 }}
                icon={<FavoriteIcon fontSize="inherit" />}
                emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Typography component="legend" variant="subtitle2">
                鼻
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <ConditionRating
                name="noseRating"
                value={symptom.noseRating}
                getLabelText={(value: number) => `${value} Heart${value !== 1 ? "s" : ""}`}
                sx={{ fontSize: 25, mt: 1, mb: 1 }}
                icon={<FavoriteIcon fontSize="inherit" />}
                emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Typography component="legend" variant="subtitle2">
                頭
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <ConditionRating
                name="headRating"
                value={symptom.headRating}
                getLabelText={(value: number) => `${value} Heart${value !== 1 ? "s" : ""}`}
                sx={{ fontSize: 25, mt: 1, mb: 1 }}
                icon={<FavoriteIcon fontSize="inherit" />}
                emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Typography component="legend" variant="subtitle2">
                体
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <ConditionRating
                name="bodyRating"
                value={symptom.bodyRating}
                getLabelText={(value: number) => `${value} Heart${value !== 1 ? "s" : ""}`}
                sx={{ fontSize: 25, mt: 1, mb: 1 }}
                icon={<FavoriteIcon fontSize="inherit" />}
                emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                readOnly
              />
            </Grid>
          </Grid>

          {symptom.temperature !== null && (
            <Grid container>
              <Grid item xs={3}>
                <Typography component="legend" variant="subtitle2">
                  体温
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography component="legend" variant="body1">
                  {symptom.temperature} ℃
                </Typography>
              </Grid>
            </Grid>
          )}
          {symptom.note !== "" && (
            <Grid container>
              <Grid item xs={3}>
                <Typography component="legend" variant="subtitle2">
                  症状メモ
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography component="legend" variant="body1">
                  {symptom.note}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Button variant="outlined" size="medium" onClick={handleUpdate}>
            編集
          </Button>
          <Button sx={{ mt: 2 }} variant="outlined" size="medium" onClick={openDialog}>
            削除
          </Button>
        </Box>
      </Box>
      <ConfirmDialog
        title="体調ログの削除"
        message={`${toMDString(date)}の体調ログを削除しますか？`}
        ok="削除"
        open={open}
        handleOk={onOkDialog}
        handleCancel={closeDialog}
      />
    </>
  );
}
