import { auth } from "./firebase";

export const logout = () => auth.signOut();

export const deleteAccount = () => {
  const user = auth.currentUser;
  if (user === null) {
    throw Error("user is null");
  }
  return user.delete();
};
