import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { auth } from "./firebase";
// import "firebaseui/dist/firebaseui.css";
// import firebaseui from "../../npm__ja";

// Configure FirebaseUI.
const uiConfig: firebaseui.auth.Config = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
    },
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
  // tosUrl: "/tos",
  // privacyPolicyUrl: "/privacy",
};

function SignInScreen(prop: firebaseui.auth.Config) {
  return <StyledFirebaseAuth uiConfig={Object.assign(uiConfig, prop)} firebaseAuth={auth} />;
}

export default SignInScreen;
